import { createApp, nextTick } from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'
import 'ant-design-vue/dist/antd.css';
//完整引入antd组件；也可按官方说明按需加载
import Antd from 'ant-design-vue/es';
// 引入公共样式
import './assets/css/index.scss'
// 获取文件方法
import getFile from '@/utils/file'
// 引入弹窗组件
import Dialog from '@/views/components/dialog/index.vue'
// 分页组件
import Apagination from '@/views/components/pagination/index.vue'

import 'video.js/dist/video-js.css' //视频插件样式

// 服务器打包的时候如果打包失败，尝试添加以下依赖
// 引入拖拽方法
import { drag } from './utils/directives-antd'
import './utils/rem'
export const app = createApp(App)

import { addHeader,addAllHeader } from '@/init/index.js'
app.config.globalProperties.$addHeader = addHeader
app.config.globalProperties.$addAllHeader = addAllHeader
import { instance, http_file } from '@/utils/http.js'
app.config.globalProperties.$http = instance
// 获取文件的api
app.config.globalProperties.get_file = getFile
app.config.globalProperties.$isDown = false
app.component('Dialog', Dialog)
app.component('Apagination', Apagination)

//拖拽组件
app.directive('drag', {
  // 当被绑定的元素挂载到 DOM 中时……
  async updated (el) {
    await nextTick()
    drag(el)
  }
})


app.use(router).use(Antd).use(store).mount('#app')
